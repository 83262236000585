//colori settabili dal js

:root{
  --primary: #fedd00;  
  --secondary: #fedd00;  
  --buttonColor: #fedd00;  
  --buttonContentColor: #fedd00;  
  --topBarTextColor: #fedd00;  
}

//variabili del scss

//variabili settate dal ws
$buttonContentColor: var(--buttonContentColor);
$topBarTextColor: var(--topBarTextColor);
$buttonColor: var(--buttonColor);
$secondary: var(--secondary);
$primary: var(--primary);

//colori statici
$gradient1: linear-gradient(to top, #f7fafc 0%, #eef1f5 100%);
$shadow1:rgba($primary, 0.10) 0px 7px 29px 0px;
$shadow2:0 4px 30px rgba(0, 34, 64, 0.066);
$medium-background:rgba($primary, 0.30);
$light-background:rgba($primary, 0.04);
$dark-background: #383838;
$primary-01: #ffede6;
$light-grey: #a5a5a5;
$yellow-01:#fff6e7;
$green-01:#e2f6d8;
$neutral:#f7fafc;
$blue-01:#e7f6ff;
$yellow:#ffbb44;
$green:#7ace4c;
$blue:#41b3f9;
$red:#f33155;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
  width: 100% !important;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-track {
      background: transparent;
  }
      
  &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 200px;
      border-right: none;
      border-left: none;
  }
  
  &::-webkit-scrollbar-track-piece:end {
      background: transparent;
  }
  
  &::-webkit-scrollbar-track-piece:start {
      background: transparent;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
box-sizing: border-box;
}
a{
  color: $primary;

  &:hover{
    color: #adadad;
  }
}
label{
  white-space: break-spaces;
}
.no-shadow{
  box-shadow: unset;
}
.shadow-2{
  box-shadow: $shadow2;
}
.border-1{
  border: solid 1px;
}
.border-color-grey{
  border-color: $light-grey;
}
.radius-1{
  border-radius: 5px;
}
.radius-2{
  border-radius: 10px;
}
.radius-3{
  border-radius: 15px;
}
.radius-4{
  border-radius: 20px;
}
.border-bottom-1{
  border: solid 1px;
  border-bottom: 1px;
}
.radius-circle{
  border-radius: 100%;
}
.radius-square{
  border-radius: 0px !important;
}
.opacity-0{
opacity: 0;
}
.opacity-01{
opacity: .1;
}
.opacity-02{
opacity: .2;
}
.opacity-03{
opacity: .3;
}
.opacity-04{
opacity: .4;
}
.opacity-05{
opacity: .5;
}
.opacity-07{
opacity: .7;
}
.opacity-08{
opacity: .8;
}
.opacity-09{
opacity: .9;
}
.fs-40{
  font-size: 40px;
}
.fs-35{
font-size: 35px;
}
.fs-30{
font-size: 30px;
}
.fs-25{
font-size: 25px;
}
.fs-20{
font-size: 20px;
}
.fs-18{
font-size: 18px;
}
.fs-17{
font-size: 17px;
}
.fs-16{
font-size: 16px;
}
.fs-15{
font-size: 15px;
}
.fs-12{
font-size: 12px;
}
.fs-10{
font-size: 10px;
}
.relative{
position: relative;
}
.absolute{
position: absolute;
}
.fixed{
position: fixed;
}
.top-0{
top: 0;
}
.right-0{
right: 0;
}
.bottom-0{
bottom: 0;
}
.left-10{
left: 10px;
}
.top-10{
top: 10px;
}
.right-10{
right: 10px;
}
.bottom-10{
bottom: 10px;
}
.left-15{
left: 15px;
}
.top-15{
top: 15px;
}
.right-15{
right: 15px;
}
.bottom-15{
bottom: 15px;
}
.left-20{
left: 20px;
}
.top-20{
top: 20px;
}
.right-20{
right: 20px;
}
.bottom-20{
bottom: 20px;
}
.left-20{
left: 20px;
}
.left-40{
left: 40px;
}
.top-40{
top: 40px;
}
.right-40{
right: 40px;
}
.bottom-40{
bottom: 40px;
}
.left-40{
left: 40px;
}
.hide{
display: none !important;
}
.block{
display: block !important;
}
.no-events{
  pointer-events: none;
  & * {
    pointer-events: none; 
  }
}
.cursor-pointer{
cursor: pointer;
}
.cursor-grab{
cursor: grab;
}
.cursor-move{
cursor: move;
}
.flex{
display: flex !important;
}
.flex-wrap{
  flex-wrap: wrap;
}
.column{
  flex-direction: column;

  label{
    margin-left: 0 !important;
  }
}
.gap-5{
gap:5px;
}
.gap-10{
gap:10px;
}
.gap-15{
gap:15px;
}
.gap-20{
gap:20px;
}
.gap-35{
gap:35px;
}
.my-10{
margin-top: 10px;
margin-bottom: 10px;
}
.my-15{
margin-top: 15px;
margin-bottom: 15px;
}
.my-20{
margin-top: 20px;
margin-bottom: 20px;
}
.mr-10{
margin-right: 10px;
}
.mr-20{
margin-right: 20px;
}
.mr-30{
margin-right: 30px;
}
.mr-50{
margin-right: 50px;
}
.mt-0{
margin-top: 0px;
}
.mt-10{
margin-top: 10px;
}
.mt-20{
margin-top: 20px;
}
.mt-30{
margin-top: 30px;
}
.mt-50{
margin-top: 50px;
}
.mt-100{
margin-top: 100px;
}
.mt-10vh{
margin-top: 10vh;
}
.ml-10{
  margin-left: 10px;
}
.m-0{
  margin: 0 !important;
}
.mb-0{
  margin-bottom: 0;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-50{
  margin-bottom: 50px;
}
.pt-5{
padding-top: 5px;
}
.pt-15{
padding-top: 15px;
}
.p-0{
  padding: 0px !important;
}
.pt-10{
padding-top: 10px;
}
.px-10{
padding-left: 10px;
padding-right: 10px;
}
.px-15{
padding-left: 15px;
padding-right: 15px;
}
.px-25{
padding-left: 25px;
padding-right: 25px;
}
.py-10{
padding-top: 10px;
padding-bottom: 10px;
}
.py-15{
padding-top: 15px;
padding-bottom: 15px;
}
.py-20{
padding-top: 20px;
padding-bottom: 20px;
}
.py-25{
padding-top: 25px;
padding-bottom: 25px;
}
.py-35{
padding-top: 35px;
padding-bottom: 35px;
}
.p-5{
  padding: 5px;
}
.p-10{
  padding: 10px;
}
.p-15{
padding: 15px;
}
.p-25{
padding: 25px;
}
.p-30{
  padding: 30px;
}
.w-100{
width: 100% !important;
}
.w-300{
  width: 300px;
}
.vw-100{
width: 100vw;
}
.mw-sm{
max-width:300px;
}
.mw-md{
max-width:400px;
}
.mw-lg{
max-width:500px;
}
.mw-xl{
max-width:600px;
}
.mw-xxl{
max-width:700px;
}
.align-center{
align-items: center;
}
.space-between{
  justify-content: space-between;
}
.space-around{
  justify-content: space-around;
}
.justify-center{
  justify-content: center;
}
.justify-end{
  justify-content: flex-end;
}
.h-100{
  height: 100%;
}
.vh-100{
  height: 100vh;
}
.text-end{
  text-align: end;
}
.text-ellipsis-1{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.text-ellipsis-2{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-ellipsis-3{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.text-sm{
  font-size: 25px;
  font-weight: 500;
}
.color-primary{
  color: $primary;
} 
.color-transparent{
  color: transparent;
} 
.color-white{
  color: #fff;
}
.color-blue{
  color: $blue;
}
.color-yellow{
  color: $yellow;
}
.color-green{
  color: $green;
}
.color-red{
  color: $red;
}
.color-black{
  color: rgb(15, 15, 15);
}
.color-secondary{
  color: $secondary;
}
.color-grey{
  color: $light-grey;
}
.hover-opacity-09:hover{
  opacity: 0.9;
}
.hover-primary:hover{
  color: $primary;
}
.hover-ml10:hover{
  transform: translateX(10px) ;
}
.hover-bg-green:hover{
  background-color: $green-01 !important;
}
.hover-bg-blue:hover{
  background-color: $blue-01 !important;
}
.hover-bg-yellow:hover{
  background-color: $yellow-01 !important;
}
.hover-bg-primary:hover{
  background-color: $primary-01 !important;
}
.fw-4{
  font-weight:400;
}
.fw-5{
  font-weight:500;
}
.fw-6{
  font-weight:600;
}
.bold{
  font-weight: bold;
}
.text-md{
  font-size: 50px;
  font-weight:700;
}
.text-xl{
  font-size: 70px;
  font-weight:700;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.bg-grey{
  background-color: #f3f3f3;
}
.bg-white{
  background-color: #fff;
}
.bg-blur{
  backdrop-filter: blur(1px) brightness(0.97);
}
.bg-blur-md{
  backdrop-filter: blur(3px) brightness(0.97);
}
.bg-blur-xl{
backdrop-filter: blur(5px) brightness(0.97);
}
.bg-primary{
    background-color: $primary !important;
}
.bg-neutral{
  background-color: $neutral !important;
}
.bg-secondary{
  background-color: $secondary !important;
}
.bg-blue{
  background-color: $blue;
}
.bg-yellow{
  background-color: $yellow;
}
.bg-green{
  background-color: $green;
}
.bg-gradient1{
  background-image: $gradient1;
}
.bg-dark-01{
background-color: rgba(0, 0, 0, 0.026);
}
.ar-16-9{
  aspect-ratio: 16/9;
}
.ar-1{
  aspect-ratio: 1/1;
}
.x-scroll{
  overflow-x: scroll;
}
.overflow-hidden{
  overflow: hidden;
}
.white-space-nowrap{
  white-space: nowrap;
}
.word-break{
  word-break: break-all;
}
.hover-scale:hover{
  transform: scale(1.1);
}
.transition-01{
  transition: .1s;
}
.transition-02{
  transition: .2s;
}
.container {
  width: 100%;
  max-width: 1220px;
}

//css generico
.layout{
  margin-bottom: 50px;
  min-height: 100vh;
}

.page-cover{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  height: 450px;
  width: 100%;
}
.form-wrapper{
  background-color: #ffffff;
  box-shadow: $shadow2;
  border-radius: 30px;
  margin-top: -100px;
  max-width: 800px;
  padding: 40px;
  width: 100%;
}
.ant-layout-content{
  padding: 40px;
  background-color: #fff;
}
.page-title{
  color: $topBarTextColor;
  font-size: 20px;
  padding: 0px 20px;
  line-height: 20px;
  width: 100%;
  align-items: end;
  display: flex;
  justify-content: flex-end;
  text-align: end;
}
.ant-form-item {
  margin-bottom: 10px;
}
.float-btn{
  position: fixed;
  right: 20px;
  bottom: 20px;
  box-shadow: $shadow2;
}
.ant-btn{
  white-space: break-spaces;
  height: auto !important;
}
//css registrazione

//css card
.card-wrapper {
  background: $primary;
  color: $topBarTextColor;
  padding: 20px 30px;
  border-radius: 15px;
  margin-top: -50px;
  box-shadow: $shadow2;
  max-width: 500px;
}
.card-code-wrapper svg{
  width: 100%;
}
//css card


//animazioni
.fadeIn{
  animation: fadeIn .3s forwards ease-in-out;
}

@keyframes fadeIn {
  0%{opacity: 0;}
  100%{opacity: 1;}
}


//responsive

//dimensione da lg in poi di ant
@media screen and (max-width:1100px) {
  .ant-layout-content{
    padding: 40px;
  }
}


//dimensione lg di ant
@media screen and (max-width:768px) {
  .page-cover{
    height: 300px;
  }
  .ant-layout-content{
    padding: 0px;
  }
  .page-cover{
    border-radius: unset;
  }
  .form-wrapper{
    max-width: 100%;
    padding: 30px;
    margin-top: -50px;
  }
}

@media screen and (max-width:610px) {

}


@media screen and (min-width:767px) {

}
@media screen and (max-width:450px) {
  .page-cover{
    height: 200px;
  }
}
@media screen and (max-width:350px) {
  .ant-sm-col-24{
    flex: 0 0 100% !important;
    max-width: 100%;
  }
  .sm-text-start{
    text-align: start;
  }
}
@media screen and (max-width:300px) {


}